<template>
	<div></div>
</template>

<script>
export default {
	name: 'TianxieSignup',

	data() {
		return {}
	},

	mounted() {
	},

	methods: {}
}
</script>

<style lang="less" scoped></style>
